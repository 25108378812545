import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '@common/climb-common.module';
import { ClimbServicesModule } from '@services/climb-services.module';

import { CohortSelectComponent, CohortFilterComponent, CohortMultiselectComponent } from './components';
import { CohortService } from './services/cohort.service';
import { JobsSharedModule } from '../jobs/jobs-shared.module';
import { UsersSharedModule } from '../user/users-shared.module';
import { StudiesSharedModule } from '../studies/studies-shared.module';
import { CohortSaveService } from './services/cohort-save.service';
import { CohortStateService } from './services/cohort-state.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
        JobsSharedModule,
        UsersSharedModule,
        StudiesSharedModule
    ],
    declarations: [
        CohortSelectComponent,
        CohortMultiselectComponent,
        CohortFilterComponent
    ],
    exports: [
        CohortSelectComponent,
        CohortMultiselectComponent,
        CohortFilterComponent
    ]
})
export class CohortSharedModule { 
    static forRoot(): ModuleWithProviders<CohortSharedModule> {
        return {
          ngModule: CohortSharedModule,
          providers: [
              CohortService,
              CohortSaveService,
              CohortStateService,
          ]
        };
    }
}
