import { ConfirmService } from '@common/confirm';
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CohortService } from './services/cohort.service';

import { 
    BaseFacet,
    BaseFacetService 
} from '@common/facet';

import { CohortTableOptions } from './helpers/cohort-table-options';
import { CohortFilterComponent } from './components';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { CopyBufferService } from '@common/services/copy-buffer.service';
import { WorkspaceFilterService } from '@services/workspace-filter.service';

import { uniqueArrayFromPropertyPath } from '@common/util';
import { DataContextService } from '@services/data-context.service';
import { TranslationService } from '@services/translation.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Cohort, Entity } from '@common/types';
import { takeUntil } from "rxjs/operators";
import { arrowClockwise, magnifier, squareOnSquare } from '@icons';
import { CohortSaveService } from './services/cohort-save.service';
import { CohortStateService } from './services/cohort-state.service';

@Component({
    selector: 'cohort-facet',
    templateUrl: './cohort-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class CohortFacetComponent extends BaseFacet<Entity<Cohort>>
    implements OnInit, OnDestroy {
    @Input() facetId: string;
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier, squareOnSquare };

    componentName = 'cohort';

    cohortTableOptions: CohortTableOptions;

    readonly COMPONENT_LOG_TAG = 'cohort-facet';
    componentFilterSubscription: Subscription;
    allowSplitting = false;

    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cohortService: CohortService,
        private confirmService: ConfirmService,
        private cellFormatterService: CellFormatterService,
        private copyBufferService: CopyBufferService,
        private dataContext: DataContextService,
        private modalService: NgbModal,
        private translationService: TranslationService,
        workspaceFilterService: WorkspaceFilterService,
        private cohortSaveService: CohortSaveService,
        private cohortStateService: CohortStateService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.cohortTableOptions = new CohortTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.cohortTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadCohortsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }

        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.allowSplitting = this.cohortService.getCohortSplittingMethodFlag();
        if (!this.allowSplitting) {
            this.cohortTableOptions.options.columns = this.cohortTableOptions.options.columns.filter((column: any) => column.displayName !== "Method");
        }
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    exitDetail(): void {
        this.selectedRows = [];
        this.reloadTable();
        this.changeView(this.LIST_VIEW);
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (!this.filter) {                
                this.filter = {};
            }
        }
    }

    async loadCohortsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands = [
            'CohortMaterial', 
            'JobCohort.Job.Study',
            'Output1',
            'Output2',
            'Output3'
        ];

        if (this.allowSplitting) {
            expands.push('cv_MatchingMethod');
        }

        this.setLoadingState(tableState.loadingMessage);

        try {
            const response = await this.cohortService.getCohorts({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter(),
                expands
            });

            const visibleColumns = this.getVisibleColumns(this.cohortTableOptions.options);
            await this.cohortService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<Cohort>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.setLoadingState();
        this.createNewCohort().then((cohort) => {
            this.stopLoading();
            this.itemToEdit = cohort;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    createNewCohort(): Promise<Entity<Cohort>> {
        const newCohort = this.cohortService.create();
        return Promise.resolve(newCohort);
    }

    openFilter() {
        const ref = this.modalService.open(CohortFilterComponent);
        const component = ref.componentInstance as CohortFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    selectedRowsChange(rows: any[]) {
        // nothing yet
    }

    copyCohorts() {
        this.copyBufferService.copy(this.selectedRows);
    }

    dragStart() {
        this.cohortService.draggedCohorts = this.selectedRows;
    }

    dragStop() {
        setTimeout(() => {
            this.cohortService.draggedCohorts = [];
        }, 500);
    }

    modelCopied(itemCopied: Entity<Cohort>) {
        this.itemToEdit = itemCopied;

        const showToast = true;
        this.loggingService.logSuccess(
            'Cohort copied',
            null,
            this.COMPONENT_LOG_TAG,
            showToast
        );

        this.changeView(this.DETAIL_VIEW);
    }

    doBulkDelete() {
        const cohortKeys = uniqueArrayFromPropertyPath(this.selectedRows, 'C_Cohort_key');
        return this.cohortService.areCohortsSafeToDelete(cohortKeys).then((safeToDelete) => {
            if (!safeToDelete) {
                this.loggingService.logWarning(
                    "Cannot delete selected cohorts: there is associated " + 
                    this.translationService.translate("Job") + 
                    " data.",
                    null, null, true
                );
                return;
            } 

            const modalTitle = 'Delete Cohorts';
            const modalText = 'Delete ' + 
                this.selectedRows.length + 
                ' selected cohorts? This action cannot be undone.';
            return this.confirmService.confirmDelete(modalTitle, modalText).then(
                // success
                () => {
                    this.setLoadingState();

                    const changes = [];
                    for (const cohort of this.selectedRows) {
                        this.cohortService.deleteCohort(cohort);
                        changes.push(...this.cohortStateService.getAllRelatedChanges(cohort));
                    }

                    this.cohortSaveService.bulkSave(changes, false, 'cohort').then(() => {
                        this.reloadTable();
                    }).then(() => {
                        this.stopLoading();
                    }).catch((error) => {
                        this.stopLoading();
                        throw error;
                    });
                },
                // cancel
                () => { /* do nothing on cancel */ }
            );
        });
        
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.cohortService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

