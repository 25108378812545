import { EntityChangeService } from './../entity-changes/entity-change.service';
import { CurrentWorkgroupService } from './current-workgroup.service';
import { AuthService } from './auth.service';
import { LogoutService } from './logout.service';
import { Injectable } from '@angular/core';
import { breeze } from './breeze';
import {
    EntityManager,
    EntityState,
    Entity,
    EntityStateSymbol,
    SaveOptions
} from 'breeze-client';

import { EntityManagerFactoryService } from './entity-manager-factory.service';
import { BaseManagerService } from './base-manager.service';
import { LoggingService } from './logging.service';

import { createBatches, generateBatchOrder } from './breeze-save-order/breeze-save-order';

import { FacetLevelSaveOptions, removeValidators } from './breeze-helpers';
import { batchArray } from '../common/util';
import { TranslationService } from './translation.service';
import { faker } from '@faker-js/faker';

/**
 * Entity Manager for the 'Data' database
 */
@Injectable()
export class DataManagerService extends BaseManagerService {
    private _managerPromise: Promise<void>;

    constructor(
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private entityManagerFactory: EntityManagerFactoryService,
        private entityChangeService: EntityChangeService,
        loggingService: LoggingService,
        logoutService: LogoutService,
        translationService: TranslationService,
    ) {
        super(loggingService, logoutService, translationService);
        this.entityManagerFactory = entityManagerFactory;

        const key = 'ds';
        window[key] = this;
    }

    init() {
        if (!this._managerPromise) {
            this._managerPromise = this.entityManagerFactory.newDataManager()
                .then((manager) => {
                    this._manager = manager;
                    this.registerEntityChanges();
                });
        }
        return this._managerPromise;
    }

    registerEntityChanges() {
        this.entityChangeService.init(this._manager);
    }

    getManager(): EntityManager {
        if (!this._manager) {
            throw new Error("Breeze entity manager for 'Data' not initialized.");
        }
        return this._manager;
    }

    /**
     * Save all changes to a single entityName type
     * @param entityName 
     */
    saveEntity(entityName: string): Promise<any> {
        return this.saveEntities([entityName]);
    }

    /**
     * Save all changes for only the given entityName types
     * @param entityNames 
     */
    saveEntities(
        entityNames: string[]
    ): Promise<any> {
        let promise: Promise<any> = Promise.resolve();
        let saveBatches: any[] = [];
        removeValidators(this._manager.metadataStore);

        const manager = this._manager;
        this.assignRequiredSystemValues();

        // Get add and delete save batches
        saveBatches = this.createBatches();
        // filter for provided entity types
        const filteredBatches = saveBatches.filter((entity) => {
            const entityShortName = entity[0].entityAspect.entityGroup.entityType.shortName;
            return entityNames.indexOf(entityShortName) >= 0;
        });

        // Add modified entity batch
        filteredBatches.push(manager.getEntities(entityNames, [breeze.EntityState.Modified]));

        for (const batch of filteredBatches) {
            if (batch.length > 0) {
                promise = promise.then(() => {
                    return manager.saveChanges(batch);
                });
            }
        }

        return promise.catch(this.queryFailed);
    }

    /**
     * Save changes for a single property on a given entity
     */
    saveChangesToProperty(entity: any, propertyName: string): Promise<any> {
        const modifiedBy = this.authService.getCurrentUserName();
        return this._saveChangesToProperty(
            entity, propertyName, modifiedBy
        ).catch(this.queryFailed);
    }

    /**
     * Save a single list of entities
     * @param entity 
     */
    saveSingleRecordBatch(saveBatch: Entity[]): Promise<any> {
        for (const entity of saveBatch) {
            this._assignRequiredSystemValues(entity);
        }

        return this.saveChangeBatches([saveBatch]);
    }

    /**
     * DO NOT USE THIS METHOD DIRECTLY
     * 
     * This is called by DataContextService to save Breeze entities for Data DB
     */
    save(): Promise<any> {
        const saveBatches = this.prepareSaveBatches();

        // 'null' batch is final and means "save everything else"
        saveBatches.push(null);

        return this.saveChangeBatches(saveBatches);
    }

    async saveFacetChanges(saveBatches: any[], facetName: string, reasonForChange: string): Promise<any> {
        const saveOptions = new FacetLevelSaveOptions();
        saveOptions.operationId = faker.datatype.uuid();
        saveOptions.facetName = facetName;
        saveOptions.reasonForChange = reasonForChange;
        return this.saveChangeBatches(saveBatches, saveOptions);
    }
    
    saveChangeBatches(saveBatches: any[], saveOptions: SaveOptions = null): Promise<any> {
        removeValidators(this._manager.metadataStore);

        let promise: Promise<any> = Promise.resolve();
        for (const batch of saveBatches) {
            // ignore empty batches
            if (batch && batch.length > 0) {
                // limit save batches to 1000 or less to avoid server error on max batch items
                for (const subBatch of batchArray(batch, 1000)) {
                    promise = promise.then(() => {
                        return this._manager.saveChanges(subBatch, saveOptions);
                    });
                }
            } else if (batch === null) {
                // null batch means "save everything else" in Breeze
                promise = promise.then(() => {
                    return this._manager.saveChanges(batch, saveOptions);
                });
            }
        }

        return promise.catch(this.queryFailed);
    }

    createBatches(entities?: Entity[]): Entity[][] {
        return createBatches(this._manager, generateBatchOrder(this._manager.metadataStore), entities);
    }

    prepareSaveBatches(entities?: Entity[]): Entity[][] {
        this.assignRequiredSystemValues(entities);
        return this.createBatches(entities);
    }

    filterEntitiesByEntityState(entities: Entity[], entityStates: EntityStateSymbol[]): any[] {
        return entities.filter(entity => entityStates.some(state => state === entity.entityAspect.entityState));
    }

    assignRequiredSystemValues(entities?: Entity[]) {
        const models = entities ? entities : this._manager.getEntities(
            null, [EntityState.Added, EntityState.Modified]
        );
        for (const entity of models) {
            this._assignRequiredSystemValues(entity);
        }
    }

    private _assignRequiredSystemValues(
        entity: any, 
        username?: string,
        workgroupKey?: number
    ) {
        if (!username) {
            username = this.authService.getCurrentUserName();
        }
        if (!workgroupKey) {
            workgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
        }
        switch (entity.entityAspect.entityState) {
            case EntityState.Added:
                // Breeze sets all default dates to 1900
                const dateCreatedSet = entity.DateCreated && 
                                     (entity.DateCreated.getYear() > 1990);

                entity.CreatedBy = username;
                entity.DateCreated = dateCreatedSet ? entity.DateCreated : Date.now();
                entity.C_Workgroup_key = workgroupKey;
                entity.ModifiedBy = entity.CreatedBy;
                entity.DateModified = entity.DateCreated;

                break;
            case EntityState.Modified:
                entity.ModifiedBy = username;
                entity.DateModified = Date.now();

                break;
            default:
                break;
        }
    }
    
    deletionRecordExists(objectTypeKey: number, recordKey: number): boolean {
        const entities: any[] = this._manager.getEntities('Deletion', breeze.EntityState.Added);

        return entities.some((entity) => {
            return (entity.C_ObjectType_key === objectTypeKey) &&
                   (entity.C_Record_key === recordKey);
        });
    }

    public importEntities(data: unknown) {
        return this._manager.importEntities(data);
    }

    public exportEntities(entities: Entity[]): string {
        return this._manager.exportEntities(entities);
    }
}
