export const facetsForFacetLevelSave = [
    'animal-detail',
    'animal-bulk-edit',
    'animal-bulk-add',
    'order-detail',
    'housing-detail',
    'housing-bulk-edit',
    'protocol-detail',    
    'schedule-detail',
    'task-detail',
    'cohort-detail',
] as const;
   
