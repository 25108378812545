import { DataContextService } from '@services/data-context.service';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
    OnDestroy,
    ViewChild,
    SimpleChanges,
    AfterViewInit,
} from '@angular/core';
import { DragulaOptions, DragulaService } from 'ng2-dragula';
import {
    notEmpty,
    range,
    arrayContainsAllValues
} from '@common/util';

import { CohortService, DraftCohortsChanges, IDraftCohorts } from '../../services/cohort.service';
import { CohortOutputStatsRowsComponent } from '../cohort-output-stats-rows/cohort-output-stats-rows.component';

import { LoggingService } from '@services/logging.service';
import { Subscription } from 'rxjs';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { Cohort, Entity, CohortMaterial } from '@common/types';
import { IFacet } from '@common/facet';
import { CohortSplitTableTemplatesComponent } from '../cohort-split-table/cohort-split-table-templates.component';
import { NgModel } from '@angular/forms';
import { TranslationService } from '@services/translation.service';
import { OverlayService } from '@services/overlay-service';
import { SelectionOption } from '../../../common/checkbox/checkbox-multi-select.component';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models';
import { DataManagerService } from '@services/data-manager.service';
import { cloneDeep, isEqual, sortBy } from '@lodash';
import { ICohortMaterialChangeEvent } from '@common/entitytable/entity-table.component';
import { CohortSaveService } from '../../services/cohort-save.service';

export enum SplittingMethod {
    PAIR_MATCHING = 'Two-Factor Pair Match',
    ENHANCED = 'Enhanced Distribution'
}

@Component({
    selector: 'cohort-splitter',
    templateUrl: './cohort-splitter.component.html',
    styles: [`
        .confirm-cohorts-text {
            display: flex;
            color: #0F101199;

            span {
                padding-left: 4px;
            }
        }
    `],
})
export class CohortSplitterComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {
    @Input() facet: IFacet;
    @ViewChildren('cohortNameField') cohortNameFields?: QueryList<NgModel>;
    @ViewChildren('splits') cohortSplits: QueryList<Entity<Cohort>>;

    @Input() cohort: Entity<Cohort>;
    @Input() selectedCohortMaterials: any[];
    // The maximum number of outputs that can be selected
    @Input() maxOutputs: number;
    @Input() outputSelectionOptions: SelectionOption[];
    @Input() draftCohortsData: IDraftCohorts;
    @Output() cohortsDraftCreated: EventEmitter<IDraftCohorts> = new EventEmitter<IDraftCohorts>();
    @Output() cohortsFromDraftCreated: EventEmitter<void> = new EventEmitter<void>();

    @ViewChildren(CohortOutputStatsRowsComponent) cohortOutputStatsRowsComponents: QueryList<CohortOutputStatsRowsComponent> = null;

    @ViewChild('templates') cohortSplitTemplates: CohortSplitTableTemplatesComponent;

    // State
    newCohortCount: number;
    // Range of allowable numbers for newCohortCount
    newCohortCountOptions: number[];
    newCohorts: Entity<Cohort>[];
    newCohortsFromDraft: Entity<Cohort>[] = [];
    excludedCohort?: Entity<Cohort>;
    excludedCohortFromDraft: Entity<Cohort>;
    groups: any[] = [];
    groupsFromDraft: unknown[] = [];
    groupCountOptions: number[];
    totalCohortGroupMaterials = 0;
    selectedCategoriesCount = 0;

    readonly COMPONENT_LOG_TAG = 'cohort-splitter';
    readonly NEW_COHORT_COUNT_DEFAULT = 2;
    readonly NEW_COHORT_COUNT_MAX = 20;
    readonly COHORT_NAME_LENGTH_MAX = 75;
    readonly INVALID_QUANTITIES_MESSAGE = 'You must select at least as many animals as new cohorts.';
    readonly TFPM_INVALID_DISTRIBUTION_MESSAGE = 'Please select more animals for distribution.';
    readonly INVALID_ANIMALS_TO_COHORT_MESSAGE = 'Please adjust the number of animals to be assigned to each split cohort.';
    readonly ED_INVALID_DISTRIBUTION_MESSAGE = 'Please select at least one output to distribute by.';
    readonly NO_NAME_MESSAGE = 'Please add a Name to generate a cohort.';
    readonly INVALID_COHORT_SIZE_SELECTION_MESSAGE = 'Sum of cohort sizes must not exceed the number of animals selected.';
    readonly INVALID_NEW_COHORTS_NUMBER_MESSAGE = 'The number of new cohorts cannot be greater than the number of selected animals.';
    readonly ED_NO_OUTPUTS = 'Please make sure at least one output is selected in the animal chart above.';
    readonly TFPM_NO_OUTPUTS = 'Please make sure Output1 and Output2 are selected in the animal chart above.';
    readonly NO_COHORT_MATERIALS_SELECTED_MESSAGE = 'Please select at least one animal.';
    // Allows HTML template to access enum values
    readonly splittingMethod: typeof SplittingMethod = SplittingMethod;

    dataContextSubcription: Subscription;
    matchingMethods: any[] = [];
    // Allows Enhanced Distribution and Two-Factor Pair Match methods (driven by feature flag)
    allowSplitting = false;
    categorykeys: any[] = [];
    subs = new Subscription();
    selectedOutputsToRandomize: string[] = [];
    selectedOutputsToRandomizeFromDraft: string[] = [];
    overlayId: string;
    isConfirmClicked = false;

    constructor(
        private cohortService: CohortService,
        private loggingService: LoggingService,
        private dataContext: DataContextService,
        private dragulaService: DragulaService,
        private vocabularyService: VocabularyService,
        private translateService: TranslationService,
        private overlaySerivce: OverlayService,
        private toastrService: ToastrService,
        private cohortSaveService: CohortSaveService,
        private dataManager: DataManagerService,
    ) { }

    async ngOnInit(): Promise<void> {
        this.newCohortCountOptions = range(1, this.NEW_COHORT_COUNT_MAX);
        this.initialize();

        // Refresh component on dataContext cancel
        this.dataContextSubcription = this.dataContext.onCancel$.subscribe(() => {
            this.initialize();
        });
        this.allowSplitting = this.cohortService.getCohortSplittingMethodFlag();
        if (this.allowSplitting) {
            this.getCVs();
            // Subscribe to drop event for animal tables
            this.subs.add(this.dragulaService.dropModel()
                .subscribe(({ el, target, name }) => {
                    // Find dragged material key
                    const draggedKey = parseInt((el as HTMLElement).dataset.key, 10);
                    // Find dragged to cohort key
                    const draggedCohortKey = parseInt((target as HTMLElement).dataset.key, 10) || parseInt((target as HTMLElement).dataset.cohort, 10);
                    // Find in new cohorts array
                    for (const cohort of this.newCohorts) {
                        // Find Material
                        const material = cohort.CohortMaterial.find((cohortMaterial: CohortMaterial) => cohortMaterial.C_CohortMaterial_key === draggedKey);
                        if (material) {
                            // Attach Material to new Cohort
                            material.C_Cohort_key = draggedCohortKey;
                            break;
                        }
                    }
                }));
            // Dragula options
            const opts: DragulaOptions<any> = {};

            // Only allow dragging if not saved already and Only allow dragging by the drag handle
            opts.moves = (el: HTMLElement, source: Element, handle: Element, sibling: Element) => {
                // const draggedKey = jQuery(el).data('key');
                const draggedKey = parseInt(el.dataset.key, 10);
                return draggedKey < 0 && handle.classList.contains('draggable');
            };
            // Configure the service for this table
            this.dragulaService.createGroup('cohort-animals', opts);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.allowSplitting) {
            const selectedCohortMaterialsChanged = this.selectedCohortMaterialsChanged(changes);
            const selectedOutputsChanged = this.selectedOutputsChanged(changes);

            if (selectedCohortMaterialsChanged || selectedOutputsChanged) {
                this.newCohortCountChanged(this.newCohortCount);
                this.methodChanged();
            }

            if (changes.draftCohortsData && changes.draftCohortsData.currentValue) {
                setTimeout(() => this.initCohortsFromDraft(this.draftCohortsData), 0);
            }
        }
    }

    ngAfterViewInit(): void {
        this.subs.add(
            this.cohortSplits.changes.subscribe((changes) => {
                if (this.overlayId && changes.length > 0) {
                    this.overlaySerivce.hide(this.overlayId);
                    this.overlayId = '';
                }
            })
        );
    }

    ngOnDestroy() {
        if (this.dataContextSubcription) {
            this.dataContextSubcription.unsubscribe();
        }
        this.subs.unsubscribe();
        this.dragulaService.destroy('cohort-animals');
    }

    /**
     * Has the contents of the array of selectedCohortMaterials changed?
     *
     * @param changes
     */
    private selectedCohortMaterialsChanged(changes: any): boolean {
        if (changes.selectedCohortMaterials && !changes.selectedCohortMaterials.firstChange) {
            const previous = changes.selectedCohortMaterials.previousValue.map((a: any) => a.C_CohortMaterial_key);
            const current = changes.selectedCohortMaterials.currentValue.map((a: any) => a.C_CohortMaterial_key);

            if (!arrayContainsAllValues(previous, current)) {
                return true;
            }
        }

        return false;
    }

    private selectedOutputsChanged(changes: any): boolean {
        if (changes.outputSelectionOptions && !changes.outputSelectionOptions.firstChange) {
            const previous = changes.outputSelectionOptions.previousValue?.map((a: any) => a.value) || [];
            const current = changes.outputSelectionOptions.currentValue.map((a: any) => a.value);

            if (!arrayContainsAllValues(previous, current)) {
                return true;
            }
        }

        return false;
    }

    initialize() {
        this.clearNewCohorts();

        // Defaults
        if (!this.newCohortCount) {
            this.newCohortCount = this.NEW_COHORT_COUNT_DEFAULT;
            this.newCohortCountChanged(this.newCohortCount);
        }
        if (!this.selectedCohortMaterials) {
            this.selectedCohortMaterials = [];
        }
    }

    /**
     * For new cohorts, cancels any dataContext changes 
     *   and clears out the state variable.
     */
    private clearNewCohorts() {
        this.onCancel();
        this.newCohorts = [];
        this.excludedCohort = null;
    }

    formValid(): boolean {
        if (!this.areCommonSettingsValid) {
            return false;
        }

        // Two-Factor Pair Match
        if (this.allowSplitting && this.isPairMatchingSelected) {
            return this.isPairMatchingValid;
        }

        // Enhanced Distribution
        if (this.allowSplitting && this.isEnhancedDistributionSelected) {
            return this._isEnhancedDistributionValid;
        }

        // Standard
        return true;
    }

    /*
    Returns a value indicating whether Two-Factor Pair Match method is selected
    */
    get isPairMatchingSelected(): boolean {
        return this.allowSplitting && this.cohort.cv_MatchingMethod?.MatchingMethod === SplittingMethod.PAIR_MATCHING;
    }

    /*
    Returns a value indicating whether Enhanced Distribution method is selected
    */
    get isEnhancedDistributionSelected(): boolean {
        return this.allowSplitting && this.isEnhancedSplittingMethod;
    }

    get isEnhancedSplittingMethod(): boolean {
        return this.cohort.cv_MatchingMethod?.MatchingMethod === SplittingMethod.ENHANCED;
    }

    /*
    Returns a value indicating whether Enhanced Distribution or Two-Factor Pair Match method is selected
    */
    get isOutputBasedMethodSelected(): boolean {
        return this.isPairMatchingSelected || this.isEnhancedDistributionSelected;
    }

    /*
    [Common validation]
    Returns a value indicating whether the settings that are common for all split methods are valid
    */
    get areCommonSettingsValid(): boolean {
        const isNewCohortsCountValid = this.newCohortCount >= 1 && this.newCohortCount <= this.NEW_COHORT_COUNT_MAX;

        return isNewCohortsCountValid &&
            this.isNameValid &&                 // cohort name is not an empty string
            this.isCohortMaterialsSelected &&   // at least one animal is selected
            this.selectedAnimalsValid;          // number of new cohorts <= number of selected animals
    }

    /*
    [Common validation]
    Returns a value indicating whether at least one animal is selected
    */
    get isCohortMaterialsSelected(): boolean {
        return !!this.selectedCohortMaterials.length;
    }

    /*
    [Common validation]
    Returns a value indicating whether the number of selected animals is greater than or equal to the number of new cohorts
    */
    get selectedAnimalsValid(): boolean {
        return this.selectedCohortMaterials.length >= this.newCohortCount;
    }
    
    /*
    [Common validation]
    Returns a value indicating whether the cohort name is not an empty string
    */
    get isNameValid(): boolean {
        return this.cohort && this.cohort.CohortName?.trim().length > 0;
    }

    /*
    [Enhanced Distribution validation] & [Two-Factor Pair Match validation]
    Returns a value indicating whether common settings of Enhanced Distribution and Two-Factor Pair Match methods are valid
    */
    get areOutputBasedMethodSettingsValid(): boolean {
        if (!this.isOutputBasedMethodSelected) {
            return true;
        }

        return this.isGroupsCountValid &&           // each split cohort has at least one animal assigned to it
            this.isCohortSizeSelectionsValid &&     // sum of the cohort sizes does not exceed the number of selected animals
            this.isCohortOutputsSelectionValid;     // correct outputs are selected
    }

    /*
    [Enhanced Distribution validation] & [Two-Factor Pair Match validation]
    Returns a value indicating whether each split cohort has at least one animal assigned to it
    */
    get isGroupsCountValid(): boolean {
        return this.groups && !!this.groups.length && !this.groups.some(x => x.count <= 0);
    }

    /*
    [Enhanced Distribution validation] & [Two-Factor Pair Match validation]
    Returns a value indicating whether the sum of the cohort sizes does not exceed the number of selected animals
    */
    get isCohortSizeSelectionsValid(): boolean {
        return this.totalCohortGroupMaterials <= this.selectedCohortMaterials.length;
    }

    /*
    [Enhanced Distribution validation] & [Two-Factor Pair Match validation]
    If Enhanced Distribution is selected, returns a value indicating whether at least one output is selected in the Animals table.
    If Two-Factor Pair Match is selected, returns a value indicating whether Output1 and Output2 are selected in the Animals table.
    */
    get isCohortOutputsSelectionValid(): boolean {
        if (this.isEnhancedDistributionSelected) {
            return Boolean(this.cohort && (this.cohort.Output1 || this.cohort.Output2 || this.cohort.Output3));
        } else if (this.isPairMatchingSelected) {
            return Boolean(this.cohort && this.cohort.Output1 && this.cohort.Output2);
        }

        return true;
    }

    /*
    [Enhanced Distribution validation]
    Returns a value indicating whether the settings of Enhanced Distribution method are valid
    */
    private get _isEnhancedDistributionValid(): boolean {
        if (!this.isEnhancedDistributionSelected) {
            return true;
        }

        return this.areOutputBasedMethodSettingsValid &&
            this.isSelectedOutputsToRandomizeValid; // at least one output is selected to distribute by
    }

    /*
    [Enhanced Distribution validation]
    Returns a value indicating whether at least one output is selected to distribute by
    */
    get isSelectedOutputsToRandomizeValid(): boolean {
        return this.selectedOutputsToRandomize && !!this.selectedOutputsToRandomize.length;
    }

    /*
    Returns a value indicating whether the settings of Two-Factor Pair Match method are valid
    */
    get isPairMatchingValid(): boolean {
        if (!this.isPairMatchingSelected) {
            return true;
        }

        const isCategoryKeysValid = this.categorykeys && this.categorykeys.length > 0;
        const isCohortGroupMaterialsValid = !!this.totalCohortGroupMaterials && (this.selectedCohortMaterials || []).length >= this.totalCohortGroupMaterials;
        const isSelectedCategoriesCountValid = this.selectedCategoriesCount >= this.totalCohortGroupMaterials;

        return this.areOutputBasedMethodSettingsValid &&
            isCategoryKeysValid &&
            isCohortGroupMaterialsValid &&
            isSelectedCategoriesCountValid;
    }


    initiateSplit() {
        this.clearNewCohorts();

        if (!this.selectedAnimalsValid) {
            this.loggingService.logWarning(
                this.INVALID_QUANTITIES_MESSAGE,
                null,
                this.COMPONENT_LOG_TAG,
                true
            );
            return;
        }
        if (this.allowSplitting) {
            if (this.isPairMatchingSelected) {
                this.getNewCohortsViaPairMatching();
                return;
            }

            if (this.isEnhancedDistributionSelected) {
                this.overlayId = this.overlaySerivce.show('');
                setTimeout(() => this.getNewCohortsViaEnhancedDistribution());
                return;
            }
        }
        this.getNewCohorts();
    }

    public initCohortsFromDraft(draftedCohortsData: IDraftCohorts): void {
        const { newCohortsChanges, excludedCohortChanges, selectedOutputsToRandomize, groupsCount } = draftedCohortsData;
        const { newCohorts, excludedCohort } = this.cohortService.initDraftCohortsData(newCohortsChanges, excludedCohortChanges);

        this.newCohorts = newCohorts;
        this.newCohortCount = this.newCohorts.length;
        this.excludedCohort = excludedCohort;
        this.newCohortsFromDraft = newCohorts;

        this.methodChanged();
        this.groups = groupsCount;
        this.groupsFromDraft = cloneDeep(this.groups);
        this.groupCountChanged();
        this.selectedOutputsToRandomize = selectedOutputsToRandomize;
        this.selectedOutputsToRandomizeFromDraft = [...selectedOutputsToRandomize]; 
    }

    public prepareDraftCohortsDataForExport(newChanges: DraftCohortsChanges, excludedChanges: DraftCohortsChanges): IDraftCohorts {
        return {
            newCohortsChanges: this.dataManager.exportEntities(newChanges as Entity<Cohort | CohortMaterial>[]),
            excludedCohortChanges: this.excludedCohort ? this.dataManager.exportEntities(excludedChanges as Entity<Cohort | CohortMaterial>[]) : '',
            selectedOutputsToRandomize: this.selectedOutputsToRandomize,
            groupsCount: this.newCohorts.some(item => !item.CohortMaterial.length) ? [] : this.groups,
        };
    }

    public areSelectedOutputsToRandomizeEqual(): boolean {
        return isEqual(sortBy(this.selectedOutputsToRandomizeFromDraft), sortBy(this.selectedOutputsToRandomize));
    }

    public isInitiateSplit(): boolean {
        return !this.draftCohortsData || !this.areSelectedOutputsToRandomizeEqual() || !isEqual(this.groups, this.groupsFromDraft);
    }

    public initiateDraft(triggerInitiateSplit: boolean): void {
        const isInitiateSplit = this.isInitiateSplit();
        if (isInitiateSplit || triggerInitiateSplit) {
            this.initiateSplit();
        }
        setTimeout(async () => {
            let entitiesToDetach: DraftCohortsChanges;
            let draftCohortsDataToExport: IDraftCohorts;
            if (!triggerInitiateSplit && !isInitiateSplit) {
                let newChangesFromExistingDraft: DraftCohortsChanges = [];
                let excludedChangesFromExistingDraft: DraftCohortsChanges = [];

                newChangesFromExistingDraft = this.cohortService.createCohortEntitiesFromDraft(this.newCohorts, false);
                if (this.excludedCohort) {
                    excludedChangesFromExistingDraft = this.cohortService.createCohortEntitiesFromDraft([this.excludedCohort], true);
                }
                draftCohortsDataToExport = this.prepareDraftCohortsDataForExport(newChangesFromExistingDraft, excludedChangesFromExistingDraft);
                entitiesToDetach = newChangesFromExistingDraft.concat(excludedChangesFromExistingDraft);
                for (const entity of entitiesToDetach) {
                    this.dataManager.detachEntity(entity);
                }
            } else {
                const newCohortsMaterialsForNewDraft: DraftCohortsChanges = this.newCohorts.map(item => item.CohortMaterial).flat();
                const newCohortsChangesForNewDraft: DraftCohortsChanges = newCohortsMaterialsForNewDraft.concat(this.newCohorts);
                const excludedCohortChangesForNewDraft: DraftCohortsChanges = this.excludedCohort?.CohortMaterial || [];

                if (this.excludedCohort) {
                    excludedCohortChangesForNewDraft.push(this.excludedCohort);
                }
                draftCohortsDataToExport = this.prepareDraftCohortsDataForExport(newCohortsChangesForNewDraft, excludedCohortChangesForNewDraft);
                entitiesToDetach = newCohortsChangesForNewDraft.concat(excludedCohortChangesForNewDraft);
                for (const entity of entitiesToDetach) {
                    this.dataManager.detachEntity(entity);
                }
            }

            try {
                await this.cohortService.saveDraftCohortsString(this.cohort, JSON.stringify(draftCohortsDataToExport));
                this.cohortsDraftCreated.emit(draftCohortsDataToExport);
                this.isConfirmClicked = false;
            } catch (error) {
                this.cohortService.cancelConfirmCohortChanges(entitiesToDetach);
            }
        });
    }

    public async onConfirmCohortsClick(): Promise<void> {
        try {
            const { entities } = this.dataManager.importEntities(this.draftCohortsData.newCohortsChanges);
            await this.cohortSaveService.bulkSave(entities, true, 'cohort');
            this.toastrService.showToast('New cohorts created.', LogLevel.Success);
            this.isConfirmClicked = true;
            this.cohortsFromDraftCreated.emit(null);
        } catch (error) {
            const changes = this.dataContext.getChanges();
            this.cohortService.cancelConfirmCohortChanges(changes);
        }
    }
    
    private getNewCohorts(): any[] {
        const materialKeys = this.getMaterialKeys(this.selectedCohortMaterials);
        this.newCohorts = this.cohortService.createRandomCohorts(
            this.newCohortCount,
            materialKeys,
            this.selectedCohortMaterials
        );

        if (notEmpty(this.newCohorts)) {
            this.assignNewCohortAttributes();
        }

        return this.newCohorts;
    }

    private getNewCohortsViaPairMatching(): any[] {
        this.newCohorts = this.cohortService.createPairMatchingCohorts(
            this.newCohortCount,
            this.categorykeys,
            this.selectedCohortMaterials,
            this.groups
        );

        if (notEmpty(this.newCohorts)) {
            this.assignNewCohortAttributes();
        }

        return this.newCohorts;
    }

    private getNewCohortsViaEnhancedDistribution(): Entity<Cohort>[] {
        const { newCohorts, excludedCohort } = this.cohortService.createEnhancedDistributionCohorts(
            this.newCohortCount,
            this.groups,
            this.selectedCohortMaterials,
            this.selectedOutputsToRandomize
        );

        this.newCohorts = newCohorts as Entity<Cohort>[];

        if (excludedCohort) {
            this.excludedCohort = excludedCohort as Entity<Cohort>;
        }

        if (notEmpty(this.newCohorts)) {
            this.assignNewCohortAttributes();
        }

        return this.newCohorts;
    }

    private assignNewCohortAttributes() {
        const baseCohortName = this.cohort.CohortName.substr(0, this.COHORT_NAME_LENGTH_MAX - 3) + ' ';
        const description = 'Split from ' + this.cohort.CohortName;

        let cohortNumber = 1;
        for (const newCohort of this.newCohorts) {
            newCohort.CohortName = baseCohortName + cohortNumber;
            newCohort.Description = description;
            newCohort.C_MatchingMethod_key = this.cohort.C_MatchingMethod_key;
            newCohort.C_Output1_key = this.cohort.C_Output1_key;
            newCohort.C_Output2_key = this.cohort.C_Output2_key;
            newCohort.C_Output3_key = this.cohort.C_Output3_key;
            cohortNumber++;
        }

        if (this.excludedCohort) {
            this.excludedCohort.CohortName = ('Excluded - ' + baseCohortName).trim();
            this.excludedCohort.Description = description;
            this.excludedCohort.C_Output1_key = this.cohort.C_Output1_key;
            this.excludedCohort.C_Output2_key = this.cohort.C_Output2_key;
            this.excludedCohort.C_Output3_key = this.cohort.C_Output3_key;
        }
    }

    private getMaterialKeys(materials: any[]): number[] {
        return materials.map((item) => {
            return item.C_Material_key;
        });
    }

    private newCohortsGenerated() {
        return notEmpty(this.newCohorts);
    }

    onCancel(): void {
        if (this.newCohortsGenerated()) {
            for (const newCohort of this.newCohorts) {
                this.cohortService.cancelCohort(newCohort);
            }
            if (this.excludedCohort) {
                this.cohortService.cancelCohort(this.excludedCohort);
            }
        }
    }

    getCVs(): Promise<any> {
        return this.vocabularyService.getCV('cv_MatchingMethods').then((data: any) => {
            this.matchingMethods = data;
            data.forEach((method: any) => {
                if (method.IsActive && method.IsDefault) {
                    if (this.cohort.C_MatchingMethod_key === null) {
                        this.cohort.C_MatchingMethod_key = method.C_MatchingMethod_key;
                    }
                }
            });
        });
    }

    matchingMethodKeyFormatter = (value: any) => {
        return value.C_MatchingMethod_key;
    }
    matchingMethodFormatter = (value: any) => {
        return value.MatchingMethod;
    }

    methodChanged() {
        if (this.isPairMatchingSelected) {
            const categories = {};
            if (this.cohort.Output1 && this.cohort.Output2) {
                this.selectedCohortMaterials.forEach((animal) => {
                    const key = animal.OutputValue1 + " - " + animal.OutputValue2;
                    if (categories[key]) {
                        categories[key].push(animal);
                    } else {
                        categories[key] = [animal];
                    }
                });
                this.categorykeys = [];
                Object.keys(categories).forEach((key) => {
                    this.categorykeys.push({
                        name: key,
                        OutputValue1: categories[key][0].OutputValue1,
                        OutputValue2: categories[key][0].OutputValue2,
                        animals: categories[key],
                        numbers: Array(categories[key].length).fill(0).map((_x, i) => i + 1),
                        selection: 0
                    });
                });
            } else {
                this.categorykeys = [];
            }
            this.groupCountOptions = Array(this.selectedCohortMaterials.length).fill(0).map((_, i) => i + 1);
            // update selectedCategoriesCount after categorykeys is changed
            this.categorySelectionChanged();
        }
    }

    removeMaterialFromCohort(material: any) {
        this.cohortService.deleteCohortMaterial(material);
    }

    /**
     * ngFor helper to track DOM changes to table rows.
     */
    trackRow = (index: number, item: any): string => {
        return `${item.C_CohortMaterial_key}-${index}`;
    }

    newCohortCountChanged(cohortCount: number): void {
        if (this.selectedAnimalsValid) {
            this.groups = Array(+cohortCount || 0).fill(0).map(() => ({ count: 0 }));
            this.groupCountChanged();
        }
    }

    groupCountChanged() {
        this.totalCohortGroupMaterials = this.groups.reduce(((acc, { count }) => acc + +count), 0);
    }

    categorySelectionChanged() {
        this.selectedCategoriesCount = this.categorykeys.reduce((acc, category: any) => acc + +category.selection, 0);
    }

    cancelExcludedCohorts() {
        this.cohortService.cancelCohort(this.excludedCohort);
        this.excludedCohort = null;
    }

    onSaveConfig() {
        this.cohortService.saveCohortSplitConfig(this.facet, this.cohortSplitTemplates.options);
    }
    
    onSelectionChange(selections: string[]) {
        this.selectedOutputsToRandomize = selections;
    }

    updateNewCohorts(event: ICohortMaterialChangeEvent = null): void {
        if (event?.action === 'remove') {
            for (const cohort of this.newCohorts) {
                cohort.CohortMaterial = cohort.CohortMaterial.filter(item => item.C_CohortMaterial_key !== event.entity.C_CohortMaterial_key);
            }
        }
        if (this.newCohorts.length) {
            this.newCohorts = [...this.newCohorts];
            if (this.isEnhancedDistributionSelected) {
                this.initiateDraft(false);
            }
        }
    }

    public onExcludedCohortChange(event: ICohortMaterialChangeEvent = null): void {
        if (event?.action === 'remove') {
            this.excludedCohort.CohortMaterial = this.excludedCohort.CohortMaterial.filter(item => item.C_CohortMaterial_key !== event.entity.C_CohortMaterial_key);
            this.initiateDraft(false);
        }
    }
    
    private getUniqueErrorMsg() {
        const isError = Boolean(this.cohortNameFields?.find(item => Boolean(item.control.errors))?.control.errors.unique);
        return isError ? this.translateService.COHORT_UNIQUE_ERROR : '';
    }

    async validate() {
        return this.getUniqueErrorMsg();
    }
}
