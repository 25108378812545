import { Injectable } from '@angular/core';
import { GlpBaseFacetStateService } from '../../services/glp-base-facet-state.service';
import {
    Entity as InternalEntity,
    Cohort,
    CohortMaterial
}
    from '../../common/types';
import { DataManagerService } from '../../services/data-manager.service';
import { Entity as BreezeEntity } from 'breeze-client';
import { ExtendedCohort } from '..';

@Injectable()
export class CohortStateService extends GlpBaseFacetStateService<Cohort> {

    constructor(protected dataManagerService: DataManagerService) {
        super(dataManagerService);
    }

    getRelatedCollectionChanges(cohort: InternalEntity<ExtendedCohort>): BreezeEntity[] {
        const changes: any = [];
        changes.push(...this.getChangesToCohortMaterials(cohort));
        
        const splits = cohort.Splits ?? [];
        for (const split of splits) {
            changes.push(...this.getChangesToCohortMaterials(split));
            changes.push(split);
        }

        return changes;
    }

    public getChangesToCohortMaterials(
        Cohort: Cohort,
    ): InternalEntity<CohortMaterial>[] {
        const CohortMaterialsId = Cohort.CohortMaterial.map(cohortMaterial => cohortMaterial.C_CohortMaterial_key);

        const allChangedCohortMaterials = (this.dataManagerService
            .getManager()
            .getChanges("CohortMaterial") ?? []) as InternalEntity<CohortMaterial>[];

        const changedCohortMaterialsForTask = allChangedCohortMaterials
            .filter((cohortMaterial) =>
                CohortMaterialsId.some(
                    (cohortMaterialId) =>
                        cohortMaterialId === cohortMaterial.C_CohortMaterial_key,
                ),
            );

        return changedCohortMaterialsForTask;
    }

    
    discardChanges(cohort: InternalEntity<Cohort>): void {
        // TODO: implement facet-level discard operation after release of GLP
        throw new Error('Method not implemented.');
    }

    getDeleteNavPropertyChanges(entity: InternalEntity<Cohort>): BreezeEntity[] {
        const changes: any = []; 
        changes.push(...this.getChangesToDeletedCohortMaterials(entity))
        return changes;
    }    

    private getChangesToDeletedCohortMaterials(cohort: Cohort): InternalEntity<CohortMaterial>[] {
        return this.dataManagerService
            .getChangesToRelatedDeletedEntityByKey<CohortMaterial>('CohortMaterial',
                'C_Cohort_key', cohort.C_Cohort_key);
    }
}
